<template>
  <div class="print-container">
    <pm-Card class="document-card">
      <template v-slot:title>
        <div class="header print-only">
          <div class="logo-area">
            <div class="school-logo"></div>
          </div>
          <div class="title-area">
            <h class="main-title">FICHA DE DADOS DO ALUNO </h>
            <p class="subtitle">Documento de Registro Escolar</p>
          </div>
        </div>
      </template>
      
      <!-- [Previous template content remains exactly the same until the end of signature-area] -->
      <template v-slot:content>
        <div class="document-content">
          <!-- Seção de Dados Pessoais -->
          <div class="section">
            <h3 class="section-title">1. DADOS PESSOAIS</h3>
            <div class="info-grid">
              <div class="info-row">
                <div class="info-item full-width">
                  <span class="label">Nome Completo:</span>
                  <span class="value">{{ info.nome }} {{ info.sobrenome }}</span>
                </div>
              </div>
              
              <div class="info-row">
                <div class="info-item">
                  <span class="label">CPF:</span>
                  <span class="value">{{ info.cpf || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Data de Nascimento:</span>
                  <span class="value">{{ nasc || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Sexo:</span>
                  <span class="value">{{ info.sexo === 'M' ? 'Masculino' : 'Feminino' }}</span>
                </div>
              </div>

              <div class="info-row">
                <div class="info-item">
                  <span class="label">Nome da Mãe:</span>
                  <span class="value">{{ info.nomeMae || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Nome do Pai:</span>
                  <span class="value">{{ info.nomePai || 'Não Informado' }}</span>
                </div>
              </div>

              <div class="info-row">
                <div class="info-item">
                  <span class="label">Estado Civil:</span>
                  <span class="value">{{ info.estadocivil || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Nacionalidade:</span>
                  <span class="value">{{ info.nacionalidade || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Naturalidade:</span>
                  <span class="value">{{ info.naturalidade || 'Não Informado' }}</span>
                </div>
              </div>

              <div class="info-row">
                <div class="info-item">
                  <span class="label">Cor/Raça:</span>
                  <span class="value">{{ info.cor || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Religião:</span>
                  <span class="value">{{ info.religiao || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Frequentará Aulas de Religião:</span>
                  <span class="value">{{ info.aulas_religiao_frequentara === '1' ? 'Sim' : 'Não' }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Seção de Documentação -->
          <div class="section">
            <h3 class="section-title">2. DOCUMENTAÇÃO</h3>
            <div class="info-grid">
              <div class="info-row">
                <div class="info-item">
                  <span class="label">RG:</span>
                  <span class="value">{{ info.rg || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Órgão Emissor:</span>
                  <span class="value">{{ info.orgao_emissor || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Data de Emissão:</span>
                  <span class="value">{{ data_emi || 'Não Informado' }}</span>
                </div>
              </div>

              <div class="info-row">
                <div class="info-item">
                  <span class="label">UF RG:</span>
                  <span class="value">{{ info.uf_rg || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Livro RG:</span>
                  <span class="value">{{ info.livro_rg || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Termo RG:</span>
                  <span class="value">{{ info.termo_rg || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Folha RG:</span>
                  <span class="value">{{ info.folha_rg || 'Não Informado' }}</span>
                </div>
              </div>

              <div class="info-row">
                <div class="info-item">
                  <span class="label">Certidão de Nascimento:</span>
                  <span class="value">{{ info.certidaoNascimento || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Certidão de Casamento:</span>
                  <span class="value">{{ info.certidaoCasamento || 'Não Informado' }}</span>
                </div>
              </div>

              <div class="info-row">
                <div class="info-item">
                  <span class="label">NIS:</span>
                  <span class="value">{{ info.nis || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Cartão SUS:</span>
                  <span class="value">{{ info.sus || 'Não Informado' }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Seção de Endereço -->
          <div class="section">
            <h3 class="section-title">3. ENDEREÇO</h3>
            <div class="info-grid">
              <div class="info-row">
                <div class="info-item">
                  <span class="label">CEP:</span>
                  <span class="value">{{ info.cep || 'Não Informado' }}</span>
                </div>
                <div class="info-item full-width">
                  <span class="label">Logradouro:</span>
                  <span class="value">{{ info.logradouro || 'Não Informado' }}, {{ info.numero || 'S/N' }}</span>
                </div>
              </div>

              <div class="info-row">
                <div class="info-item">
                  <span class="label">Complemento:</span>
                  <span class="value">{{ info.complemento || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Bairro:</span>
                  <span class="value">{{ info.bairro || 'Não Informado' }}</span>
                </div>
              </div>

              <div class="info-row">
                <div class="info-item">
                  <span class="label">Cidade:</span>
                  <span class="value">{{ info.cidade || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Estado:</span>
                  <span class="value">{{ info.estado || 'Não Informado' }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Seção de Informações Adicionais -->
          <div class="section">
            <h3 class="section-title">4. INFORMAÇÕES ADICIONAIS</h3>
            <div class="info-grid">
              <div class="info-row">
                <div class="info-item">
                  <span class="label">Tipo Sanguíneo:</span>
                  <span class="value">{{ info.tipo_sanguineo || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Alérgico:</span>
                  <span class="value">{{ info.alergico == 1 ? 'Sim' : 'Não' }}</span>
                </div>
                <div class="info-item" v-if="info.alergico == 1">
                  <span class="label">Tipo de Alergia:</span>
                  <span class="value">{{ info.tipo_alergia || 'Não Informado' }}</span>
                </div>
              </div>

              <div class="info-row">
                <div class="info-item">
                  <span class="label">Necessidades Especiais:</span>
                  <span class="value">{{ info.possuiDeficiencia == 1 ? 'Sim' : 'Não' }}</span>
                </div>
                <div class="info-item full-width" v-if="info.possuiDeficiencia == 1">
                  <span class="label">Especificação:</span>
                  <span class="value">{{ info.deficiencia || 'Não Informado' }}</span>
                </div>
              </div>

              <div class="info-row">
                <div class="info-item">
                  <span class="label">Vacinação:</span>
                  <span class="value">{{ info.vacinacao == 1 ? 'Em dia' : 'Pendente' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Utiliza Transporte:</span>
                  <span class="value">{{ info.utiliza_transporte == 1 ? 'Sim' : 'Não' }}</span>
                </div>
                <div class="info-item" v-if="info.utiliza_transporte == 1">
                  <span class="label">Tipo de Transporte:</span>
                  <span class="value">{{ info.qual_transporte || 'Não Informado' }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Seção de Informações Socioeconômicas -->
          <div class="section">
            <h3 class="section-title">5. INFORMAÇÕES SOCIOECONÔMICAS</h3>
            <div class="info-grid">
              <div class="info-row">
                <div class="info-item">
                  <span class="label">Renda Per Capita:</span>
                  <span class="value">{{ info.renda_per_capita || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Número de Pessoas na Residência:</span>
                  <span class="value">{{ info.numero_pessoas_residencia || 'Não Informado' }}</span>
                </div>
              </div>

              <div class="info-row">
                <div class="info-item">
                  <span class="label">Bolsa Família:</span>
                  <span class="value">{{ info.bolsa_familia == '1' ? 'Sim' : 'Não' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Conectividade:</span>
                  <span class="value">{{ info.conectividade == '1' ? 'Sim' : 'Não' }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Seção de Contato -->
          <div class="section">
            <h3 class="section-title">6. INFORMAÇÕES DE CONTATO</h3>
            <div class="info-grid">
              <div class="info-row">
                <div class="info-item">
                  <span class="label">E-mail:</span>
                  <span class="value">{{ info.email || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">E-mail do Responsável:</span>
                  <span class="value">{{ info.email_responsavel || 'Não Informado' }}</span>
                </div>
              </div>

              <div class="info-row">
                <div class="info-item">
                  <span class="label">CPF do Responsável:</span>
                  <span class="value">{{ info.cpf_responsavel || 'Não Informado' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">Nome do Responsável:</span>
                  <span class="value">{{ info.responsavel || 'Não Informado' }}</span>
                </div>
              </div>

              <div class="info-row">
                <div class="info-item" v-if="info.telefone && info.telefone.length > 0">
                  <span class="label">Telefones:</span>
                  <span class="value">
                    <template v-for="(tel, index) in info.telefone" :key="index">
                      {{ tel.nome }}{{ index < info.telefone.length - 1 ? ', ' : '' }}
                    </template>
                  </span>
                </div>
                <div class="info-item" v-else>
                  <span class="label">Telefones:</span>
                  <span class="value">Não Informado</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Área de Assinaturas -->
          <div class="signature-area print-only">
            <div class="signature-line">
              <span>_________________________________</span>
              <p>Assinatura do Responsável</p>
            </div>
            <div class="signature-line">
              <span>_________________________________</span>
              <p>Assinatura do Secretário(a)</p>
            </div>
            <div class="date-line">
              <!-- <p>Cardoso Moreira, <span>____</span> de <span>________</span> de <span>____</span></p> -->
            </div>
          </div>
        </div>
      </template>


      <!-- Footer com botões de navegação -->
      <template v-slot:footer v-if="!hide_buttons">
        <div class="grid grid-nogutter justify-content-between no-print">
          <pm-Button
            label="Anterior"
            @click="prevPage()"
            icon="pi pi-angle-left"
          />

          <div class="button-group">
            <pm-Button
              v-if="this.info.id"
              label="Imprimir"
              @click="printDocument"
              icon="pi pi-print"
              class="p-button-secondary mr-2"
            />

            <pm-Button
              v-if="this.info.id"
              label="Gerar PDF"
              @click="generatePDF"
              icon="pi pi-file"
              class="p-button-info"
            />
          </div>

          <pm-Button
            label="Enviar"
            @click="enviarDados()"
            icon="pi pi-check"
            iconPos="right"
            class="p-button-success"
          />
        </div>
      </template>
    </pm-Card>
  </div>
</template>

<script>

import html2pdf from 'html2pdf.js';

export default {
  props: {
    dadosPessoa: { type: Array, default: () => [] },
    pg: {},
  },
  data() {
    return {
      info: {
        nome: "",
        nomeMae: "",
        nomePai: "",
        possuiDeficiencia: false,
        deficiencia: "",
        nee: false,
        observacao: "",
        email: "",
        senha: "",
        estadocivil: "Solteiro",
        certidaoCasamento: "",
        certidaoNascimento: "",
        rg: "",
        dataEmissao: "",
        cpf: "",
        dataNascimento: "",
        sexo: "",
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: "",
        telefone: [],
        escola_id: 0,
        renda_per_capita: "",
        numero_pessoas_residencia: "",
        bolsa_familia: "0",
        email_responsavel: "",
        renda_per_capita: "",
      },
      nasc: "",
      data_emi: "",
      hide_buttons: false,
      loading_print: false,
    };
  },
  methods: {
    prevPage() {
      this.$emit("prevPage", { info: this.info });
    },
    enviarDados() {
      this.$emit("enviarDados", { info: this.info });
    },
    printDocument() {
      this.loading_print = true;
      
      // Get the document card content
      const content = document.querySelector('.document-card').cloneNode(true);
      
      // Remove no-print elements
      const noPrintElements = content.querySelectorAll('.no-print');
      noPrintElements.forEach(el => el.remove());
      
      // Create the print window HTML
      const printHTML = `
          <!DOCTYPE html>
          <html>
          <head>
            <title>Ficha de Dados do Aluno</title>
            <style>
              @media print {
                body {
                  margin: 0;
                  padding: 0;
                  background: white;
                }
                .print-container {
                  width: 210mm;
                  min-height: 297mm;
                  padding: 10mm;
                  margin: 0;
                  background: white;
                  color: black;
                }
                @page {
                  size: A4;
                  margin: 0;
                }
                .document-card {
                  box-shadow: none !important;
                  border: none !important;
                }
                .info-grid {
                  page-break-inside: avoid;
                }
                .section {
                  page-break-inside: avoid;
                }
                .value {
                  color: black !important;
                }
                .signature-area {
                  page-break-inside: avoid;
                  margin-top: 30px;
                }
                .signature-line span {
                  border-top: 1px solid black;
                }
              }
              
              /* Non-print styles */
              body {
                margin: 0;
                padding: 20px;
                background: #f4f4f4;
              }
              .print-container {
                background: white;
                padding: 20px;
                margin: 0 auto;
                max-width: 210mm;
                min-height: 297mm;
                box-shadow: 0 0 10px rgba(0,0,0,0.1);
              }
              .print-header {
                text-align: center;
                margin-bottom: 20px;
                padding: 10px;
                background: #f8f9fa;
                position: sticky;
                top: 0;
              }
              .print-button {
                padding: 10px 20px;
                background: #007bff;
                color: white;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                font-size: 16px;
              }
              .print-button:hover {
                background: #0056b3;
              }
            </style>
          </head>
          <body>
            <div class="print-header">
              <button class="print-button" onclick="window.print();">Imprimir Documento</button>
            </div>
            <div class="print-container">
              ${content.outerHTML}
            </div>
          </body>
          </html>
        `;
      
      // Open new window and write content
      const printWindow = window.open('', '_blank', 'width=800,height=600');
      printWindow.document.write(printHTML);
      printWindow.document.close();
      
      // Handle print window close
      const checkWindowClosed = setInterval(() => {
        if (printWindow.closed) {
          clearInterval(checkWindowClosed);
          this.loading_print = false;
        }
      }, 1000);
    },
    generatePDF() {
      this.hide_buttons = true;
      const element = document.querySelector('.document-card'); // Ou o seletor adequado
      html2pdf(element, {
          filename: 'ficha-de-dados-do-aluno.pdf',
          html2canvas: {
              scale: 2, // Aumenta a qualidade da imagem
              backgroundColor: "#f4f4f4", // Alterar a cor de fundo para um tom suave
              logging: true, // Para ajudar a depurar o processo de conversão
              x: 0, 
              y: 0
          },
          jsPDF: {
              unit: 'pt', 
              format: 'a4',  // Definindo o tamanho da página como A4
              orientation: 'portrait', // Define a orientação da página
              margins: { top: 40, left: 40, bottom: 40 }, // Ajuste das margens
          }
      });
      setTimeout(() => {
        this.hide_buttons = false;
      }, 1000);
    }
  },
  beforeMount() {
    this.info.nome =
      this.dadosPessoa.nome != undefined
        ? this.dadosPessoa.nome
        : this.info.nome;
    this.info.sobrenome =
      this.dadosPessoa.sobrenome != undefined
        ? this.dadosPessoa.sobrenome
        : this.info.sobrenome;
    this.info.nomeMae =
      this.dadosPessoa.nomeMae != undefined
        ? this.dadosPessoa.nomeMae
        : this.info.nomeMae;
    this.info.nomePai = this.dadosPessoa.nomePai;
    this.info.possuiDeficiencia = this.dadosPessoa.possuiDeficiencia;
    this.info.deficiencia = this.dadosPessoa.deficiencia;
    this.info.observacao = this.dadosPessoa.observacao;
    this.info.nee = this.dadosPessoa.nee;
    this.info.mediacao_escolar = this.dadosPessoa.mediacao_escolar;
    this.info.email = this.dadosPessoa.email;
    this.info.emailPadrao = this.dadosPessoa.emailPadrao;
    this.info.senha = this.dadosPessoa.senha;
    this.info.confirmarSenha = this.dadosPessoa.confirmarSenha;
    this.info.estadocivil =
      this.dadosPessoa.estadocivil != undefined
        ? this.dadosPessoa.estadocivil
        : this.info.estadocivil;
    this.info.certidaoNascimento = this.dadosPessoa.certidaoNascimento;
    this.info.certidaoCasamento = this.dadosPessoa.certidaoCasamento;
    this.info.rg = this.dadosPessoa.rg;
    this.info.rgDataEmissao =
      this.dadosPessoa.rgDataEmissao != undefined
        ? this.dadosPessoa.rgDataEmissao
        : this.info.rgDataEmissao;
    this.info.cpf = this.dadosPessoa.cpf;
    this.info.cpfDele = this.dadosPessoa.cpfDele;
    this.info.dataNascimento =
      this.dadosPessoa.dataNascimento != undefined
        ? this.dadosPessoa.dataNascimento
        : this.info.dataNascimento;
    this.info.cep =
      this.dadosPessoa.cep != undefined ? this.dadosPessoa.cep : this.info.cep;
    this.info.estado = this.dadosPessoa.estado;
    this.info.cidade = this.dadosPessoa.cidade;
    this.info.bairro = this.dadosPessoa.bairro;
    this.info.logradouro = this.dadosPessoa.logradouro;
    this.info.numero = this.dadosPessoa.numero;
    this.info.complemento = this.dadosPessoa.complemento;
    this.info.telefone =
      this.dadosPessoa.telefone == undefined ? [] : this.dadosPessoa.telefone;
    this.info.sexo =
      this.dadosPessoa.sexo != undefined
        ? this.dadosPessoa.sexo
        : this.info.sexo;
    this.info.foto = this.dadosPessoa.foto;
    this.info.id =
      this.dadosPessoa.id != undefined ? this.dadosPessoa.id : this.info.id;
    this.info.login_id =
      this.dadosPessoa.login_id != undefined
        ? this.dadosPessoa.login_id
        : this.info.login_id;
    this.info.matricula = this.dadosPessoa.matricula;
    this.info.alergico =
      this.dadosPessoa.alergico != undefined
        ? this.dadosPessoa.alergico
        : this.info.alergico;

    this.info.vacinacao =
      this.dadosPessoa.vacinacao != undefined
        ? this.dadosPessoa.vacinacao
        : this.info.vacinacao;



    this.info.tipo_alergia =
      this.dadosPessoa.tipo_alergia != undefined
        ? this.dadosPessoa.tipo_alergia
        : this.info.tipo_alergia;
    this.info.nacionalidade =
      this.dadosPessoa.nacionalidade != undefined
        ? this.dadosPessoa.nacionalidade
        : this.info.nacionalidade;
    this.info.naturalidade =
      this.dadosPessoa.naturalidade != undefined
        ? this.dadosPessoa.naturalidade
        : this.info.naturalidade;
    this.info.cor =
      this.dadosPessoa.cor != undefined ? this.dadosPessoa.cor : this.info.cor;
    this.info.religiao =
      this.dadosPessoa.religiao != undefined
        ? this.dadosPessoa.religiao
        : this.info.religiao;
    this.info.aulas_religiao_frequentara =
      this.dadosPessoa.aulas_religiao_frequentara != undefined
        ? this.dadosPessoa.aulas_religiao_frequentara
        : "0";
    this.info.usoDaImage =
      this.dadosPessoa.usoDaImage != undefined
        ? this.dadosPessoa.usoDaImage
        : "0";
    this.info.responsavel =
      this.dadosPessoa.responsavel != undefined
        ? this.dadosPessoa.responsavel
        : "";
    this.info.orgao_emissor =
      this.dadosPessoa.orgao_emissor != undefined
        ? this.dadosPessoa.orgao_emissor
        : "";
    this.info.uf_rg =
      this.dadosPessoa.uf_rg != undefined ? this.dadosPessoa.uf_rg : "";
    this.info.livro_rg =
      this.dadosPessoa.livro_rg != undefined ? this.dadosPessoa.livro_rg : "";
    this.info.termo_rg =
      this.dadosPessoa.termo_rg != undefined ? this.dadosPessoa.termo_rg : "";

    this.info.nis =
      this.dadosPessoa.nis != undefined ? this.dadosPessoa.nis : "";
    this.info.sus =
      this.dadosPessoa.sus != undefined ? this.dadosPessoa.sus : "";

    this.info.folha_rg =
      this.dadosPessoa.folha_rg != undefined ? this.dadosPessoa.folha_rg : "";
    this.info.conectividade =
      this.dadosPessoa.conectividade != undefined
        ? this.dadosPessoa.conectividade
        : "1";
    this.info.tipos_dispotivos =
      this.dadosPessoa.tipos_dispotivos != undefined
        ? this.dadosPessoa.tipos_dispotivos
        : [];
    this.info.tipo_sanguineo =
      this.dadosPessoa.tipo_sanguineo != undefined
        ? this.dadosPessoa.tipo_sanguineo
        : "";
    this.info.utiliza_transporte =
      this.dadosPessoa.utiliza_transporte != undefined
        ? this.dadosPessoa.utiliza_transporte
        : "1";
    this.info.qual_transporte =
      this.dadosPessoa.qual_transporte != undefined
        ? this.dadosPessoa.qual_transporte
        : "";
    this.info.estadocivil =
      this.dadosPessoa.estadocivil != undefined
        ? this.dadosPessoa.estadocivil
        : "Solteiro";
    this.info.emailDele = this.dadosPessoa.emailDele;

    this.info.cpf_responsavel =
      this.dadosPessoa.cpf_responsavel != undefined
        ? this.dadosPessoa.cpf_responsavel
        : "";


    this.info.renda_per_capita = 
    this.dadosPessoa.renda_per_capita != undefined 
      ? this.dadosPessoa.renda_per_capita 
      : "";


    this.info.numero_pessoas_residencia = 
      this.dadosPessoa.numero_pessoas_residencia != undefined 
        ? this.dadosPessoa.numero_pessoas_residencia 
        : "";
        
    this.info.bolsa_familia = 
      this.dadosPessoa.bolsa_familia != undefined 
        ? this.dadosPessoa.bolsa_familia 
        : "0";
        
    this.info.email_responsavel = 
      this.dadosPessoa.email_responsavel != undefined 
        ? this.dadosPessoa.email_responsavel 
        : "";


    const r = this.info.dataNascimento.split("-");
    this.nasc = r[2] + "/" + r[1] + "/" + r[0];

    const s = this.info.rgDataEmissao.split("-");
    this.data_emi = s[2] + "/" + s[1] + "/" + s[0];

    this.info.escola_id = this.dadosPessoa.escola_id;
  },
};
</script>

<style scoped>
.date-line {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.date-line p {
  margin: 0; /* Removes default margin */
  white-space: nowrap; /* Ensures the text stays on one line */
}
/* Base print container */
.print-container {
  width: 210mm;  /* A4 width */
  min-height: 297mm;  /* A4 height */
  padding: 10mm;  /* Safe print margin */
  margin: 0 auto;
}

/* Print-specific styles */
@media print {
  .print-container {
    width: 210mm;
    height: 297mm;
    padding: 10mm;
    margin: 0;
  }
  
  .no-print {
    display: none !important;
  }
}

/* Document card styles */
.document-card {
  height: 100%;
  padding: 0;
}

/* Header styles */
.header {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;  /* Centraliza horizontalmente */
  text-align: center;      /* Centraliza o texto dentro do elemento */
}

.title-area {
  text-align: center;     /* Garante que o texto dentro da title-area esteja centralizado */
  width: 100%;           /* Ocupa toda a largura disponível */
}

.main-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  text-align: center;    /* Garante que o título principal esteja centralizado */
}

.subtitle {
  font-size: 14px;
  margin: 5px 0 0;
  text-align: center;    /* Garante que o subtítulo esteja centralizado */
}

/* Section styles */
.section {
  margin-bottom: 15px;
}

.section-title {
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 8px 0;
  padding-bottom: 3px;
  border-bottom: 1px solid #ccc;
}

/* Info grid styles */
.info-grid {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.info-row {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.info-item {
  flex: 1;
  min-width: 150px;
  font-size: 12px;
}

.info-item.full-width {
  flex: 0 0 100%;
}

.label {
  font-weight: bold;
  margin-right: 5px;
}

.value {
  color: #444;
}

/* Signature area styles */
.signature-area {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.signature-line {
  text-align: center;
  flex: 1;
}

.signature-line span {
  display: block;
  margin-bottom: 5px;
}

.signature-line p {
  margin: 0;
  font-size: 12px;
}

.date-line {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
}

/* Additional print optimizations */
@page {
  size: A4;
  margin: 0;
}

/* Ensure content fits */
.document-content {
  max-height: 257mm; /* 297mm - 40mm for margins and header */
  overflow: hidden;
}
</style>